<template>
    <yd-layout>
      <yd-navbar slot="navbar" title="推广码" bgcolor="#F5F6F7" fontsize=".36rem" height=".88rem" class="white">
            <div @click="handleBack" slot="left">
                <i class="iconfont iconfanhuiback"></i>
            </div>
      </yd-navbar>
      <div v-show="tabs == 1" class="wrap" style="overflow: hidden;">
        <div class="main wrap">
            <div class="bg"></div>
            <div class="content wrap">
                <div class="content-title">
                    <h3 class="title">{{userInfo.username || ''}}</h3>
                    <p class="label">{{userInfo.companyName || ''}}</p>
                </div>
                <div class="content-body">
                    <h3 class="title">No.{{userInfo.id || ''}}</h3>
                    <div class="qrcode-box">
                        <canvas class="qrcode" ref="qrcode"></canvas>
                        <img class="logo" src="../assets/icon_logo@2x.png" srcset="../assets/icon_logo@2x.png 2x,../assets/icon_logo@3x.png 3x" alt="">
                    </div>
                    <yd-button @click.native="handleSaveShareImg()" type="hollow" shape="circle">保存推广码</yd-button>
                    <!-- <input type="file" ref="upFile" @change="selectImage"/> -->
                    <!-- <img :src="imageUrl" alt=""> -->
                </div>
            </div>
        </div>
        <!-- 生成分享图片 -->
        <div class="main img-box wrap" ref="shareImg">
            <div class="bg"></div>
            <div class="content wrap">
                <div class="content-title">
                    <h3 class="title">{{userInfo.username || ''}}</h3>
                    <p class="label">{{userInfo.companyName || ''}}</p>
                </div>
                <div class="content-body">
                    <h3 class="title">No.{{userInfo.id || ''}}</h3>
                    <div class="qrcode-box">
                        <canvas class="qrcode" ref="qrcode2"></canvas>
                        <img class="logo" style="width: 36px;height: 36px;" src="../assets/icon_logo@2x.png" alt="">
                    </div>
                    <p class="label">扫码下载购花APP</p>
                </div>
            </div>
        </div>
      </div>
        <!-- 推广记录 -->
        <popularize-list 
            v-show="tabs == 2"
            :userId="userInfo.id"
            :startTime="userInfo.createTime"
        />

        <yd-tabbar slot="tabbar">
            <ul>
                <li>
                    <span @click="handleTab(1)" :class="{active:tabs==1}">
                        <i :class="{icontuiguangma3x:tabs==1,icontuiguangma23x:tabs!=1}" class="iconfont"></i>推广码
                    </span>
                </li>
                <li>
                    <span @click="handleTab(2)" :class="{active:tabs==2}">
                        <i :class="{icontuiguangjilu3x:tabs==2,icontuiguangjilu23x:tabs!=2}" class="iconfont"></i>推广记录
                    </span>
                </li>
            </ul>
        </yd-tabbar>
  
    </yd-layout>
      
</template>
  
<script>
  // @ is an alias to /src
    import Qrcode from 'qrcode';
    import html2canvas from 'html2canvas'
    import PopularizeList from './components/PopularizeList'
    import {setToken,getToken} from '@/utils/auth'
    import {mapGetters} from 'vuex'
    import OSS from 'ali-oss';
  export default {
    name: 'ContractConfirm',
    components: {
      PopularizeList
    },
    computed: {
      ...mapGetters(['userInfo'])
    },
    data() {
        return {
            tabs:1,
            options:{
                width:250,
                height:250,
            },
            imageUrl:'',
            check: false,
            hasContract:false,
            phone:'', //17671605650
            deviceId:'', //863064961218924
            phoneModel:'', //HUAWEI HUAWEI MLA-AL10
            centent:'',
            percentage:0,//进度
        }
    },
    watch: {
        percentage(nV){
            console.log(nV)
        }
    },
    mounted () {
        const {$router,$route} = this;
        this.handleReLogin();
        this.handleInitImg();
    },
    methods: {
        
        //获取参数
        getUrlParam(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return decodeURIComponent(r[2]);//unescape(r[2]);
            return null;
        },
        //重新登录
        handleReLogin(){
            let token = getToken();
            if(!token){
                this.$router.push({path:'/login'});
            }
        },
        //退出登录
        handleBack(){
            this.$dialog.confirm({
                title: '退出登录',
                mes: '确定退出登录？',
                opts: () => {
                    setToken('');
                    this.$router.push({path:'/login'});
                }
            });
        },
        //底部tabbar切换
        handleTab(val){
            if(val == 1){
                this.handleInitImg();
            }
            this.tabs = val;
        },
        //生成二维码
        handleInitImg(){
            const {userInfo:{id,downloadUrl}} = this;
            // const path = `https://app.yzbfp.com/?userNo=${id}&type=1`//
            const path = `${downloadUrl}?userNo=${id}&type=1`;
            
            Qrcode.toCanvas(this.$refs.qrcode,path,this.options,(err)=>{
                
            })
            Qrcode.toCanvas(this.$refs.qrcode2,path,this.options,(err)=>{
                
            })
        },
        //生成分享图片
        handleSaveShareImg(){
            html2canvas(this.$refs.shareImg, {})
            .then((canvas) => {
                let imageUrl = canvas.toDataURL('image/png'); // 将canvas转成base64图片格式
                // this.imageUrl = imageUrl;
                // this.downs(imageUrl)
                this.uploadAndDeleImg(imageUrl)
            });
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), 
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        },
        
        async uploadAndDeleImg(imgBase64) {
            const {status,data} = await this.$store.dispatch('getOSSPolicy')
            
            if (status === 200) {
                
                let Base64 = require('js-base64').Base64
                const { accessKeyId, accessKeySecret, bucketName, endpoint, url } = data
                const client = new OSS({
                    region: 'oss-cn-beijing', // Base64.decode(url),//"oss-cn-shenzhen", //oss地址
                    // endpoint: Base64.decode(endpoint),
                    accessKeyId: Base64.decode(accessKeyId),
                    accessKeySecret: Base64.decode(accessKeySecret),
                    bucket: Base64.decode(bucketName), // 阿里云bucket文件夹名
                    secure: true, //使
                })    
                console.log(Base64.decode(url))
                //Object.assign({},{a:555},{file:'file'})
                const fileNname = '购花供应链_'+new Date().getTime()+'.png';
                const _this = this;
                // 云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，部署在服务端使用RAM子账号或STS，部署在客户端使用STS。
                var file = this.dataURLtoFile(imgBase64,fileNname)
                
                let ret = await client.put(fileNname, file)
                
                if(ret.res.status === 200){
                    await this.downs(ret.url,fileNname)
                    // this.$store.dispatch('deleOSSFile',{objectName:fileNname})
                }
            }
        },
        
        //下载图片
        downs(Url,names) {
            let a = document.createElement("a");
            // url.blob().then(blob=>{
            //     a.href = URL.createObjectURL(blob);
            // })
            a.href = Url;
            a.download = names; //图片名
            // document.body.appendChild(a);
            a.click();
        }
    },
  }
</script>
<style lang="scss" scoped>
    .bg{
      height: 4.3rem;
      display: flex;
      align-items: center;
      padding:0 .5rem .9rem;
      background: url('../assets/bg_banner@2x.png') no-repeat center;
      background-size: cover;
    }
    @media only screen and (min-device-pixel-ratio: 3){
      .bg{
        background: url('../assets/bg_banner@3x.png') no-repeat center;
      }
    }
    /deep/ .yd-scrollview{
        height: auto;
        background: var(--border-color-input);
    }
    .main{
      height: inherit;
      background: #2AAAA1;
    }
    .img-box{
        position: absolute;
        width: 100%;
        top:0;
        z-index: -1;
    }
    //合同内容
    .content{
      background: #fff;
      margin: -.9rem .3rem .8rem;
      border-radius: .12rem;
      padding: .4rem 0;
      .content-title{
        font-size: .36rem;
        font-weight: normal;
        position: relative;
        margin-top: 0;
        padding:0 .4rem .32rem;
        border-bottom: 1px solid var(--bg-color-body);
        .title{
            font-size: .48rem;
            font-weight: 500;
        }
        .label{
            color:var(--text-color-label);
            font-size: .24rem;
            margin-top: .1rem;
        }
      }
      .content-title::after{
        content: '';
        display: inline-block;
        position: absolute;
        bottom: .42rem;
        left: 0;
        top:.2rem;
        margin: auto;
        width: .08rem;
        background: #FA8835;
      }
      .content-body{
            text-align: center;
            padding: .3rem;
            position: relative;
            .title{
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                z-index: 9;
                margin: auto;
                font-size: .32rem;
                font-weight: normal;
            }
            .qrcode-box{
                position: relative;
                margin-top:-.28rem;
                .qrcode{
                    width: 4rem;
                    height: 4rem;
                }
                .logo{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
            button{
                padding: 0 1rem;
                background: #fff;
                height: .88rem;
                font-size: .32rem;
                font-weight: normal;
                color: var(--text-color-label);
            }
            .label{
                position: absolute;
                bottom:.2rem;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 9;
                text-align: center;
                color:var(--text-color-label);
                font-size: .32rem;
                font-weight: normal;
            }
      }
    }
    //
    .yd-tabbar{
        border-top: 0;
        height: 1.3rem;
        ul{
            display: flex;
            width: 100%;
            li{
                flex:1;
                width: 50%;
                text-align: center;
                span{
                    display: inline-block;
                    padding: .2rem .5rem;
                    border-radius: 1000px;
                    font-size: .28rem;
                    color:var(--text-color-label);
                    line-height: 20px;
                    .iconfont{
                        font-size: .3rem;
                        color:var(--text-color-label);
                        margin-right: .13rem;
                    }
                }
                span.active{
                    color:#fff;
                    background: linear-gradient(135deg, #109A92 0%, #0FABA2 100%);
                    .iconfont{
                        color:#fff;
                    }
                }
            }
        }
    }
</style>
  