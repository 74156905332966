<template>
    <yd-layout id="screen">
        <yd-navbar slot="navbar" title="筛选" bgcolor="#F5F6F7" fontsize=".36rem" height=".88rem" class="white">
            <div @click="handleBack" slot="left">
                <i class="iconfont iconfanhuiback"></i>
            </div>
        </yd-navbar>
        <div class="main wrap">
            <div class="item">
                <p class="label">审核状态</p>
                <yd-radio-group v-model="status" color="#0FABA2" class="item-body">
                    <yd-radio :val="1">全部</yd-radio>
                    <yd-radio :val="2">审核中</yd-radio>
                    <yd-radio :val="3">待激活</yd-radio>
                    <yd-radio :val="4">审核通过</yd-radio>
                    <yd-radio :val="5">审核不通过</yd-radio>
                </yd-radio-group>
            </div>
            <div class="item">
                <p class="label">时间范围</p>
                <div class="item-body time-box">
                    <yd-datetime type="date" v-model="startTime2" :init-emit="false" placeholder="开始时间"></yd-datetime>
                    <span class="middle">至</span>
                    <yd-datetime type="date" v-model="endTime2" :init-emit="false" placeholder="结束时间"></yd-datetime>
                </div>
            </div>
        </div>

        <yd-tabbar slot="tabbar">
            <div class="box">
                <yd-button type="hollow" @click.native="handleReset()" >重置</yd-button>
                <yd-button @click.native="handleSubmit()" type="primary">确认</yd-button>
            </div>
        </yd-tabbar>
  
    </yd-layout>
</template>

<script>
    import {formatDate} from '@/utils/formatDate'
    export default {
        data() {
            return {
                status: '',
                startTime2:'',
                endTime2:''
            }
        },
        props: {
            startTime: {
                type: String,
                default: ''
            },
            endTime: {
                type: String,
                default: ''
            },
            state: {
                type: Number,
                default: 1
            },
            // onConfirm: {
            //     type: Function,
            //     default: ''
            // },
            // onReset: {
            //     type: Function,
            //     default: ''
            // },
        },
        mounted () {
            console.log(this.startTime)
            this.startTime2 = this.startTime;
            this.endTime2 = this.endTime;
            this.status = this.state;
        },
        methods: {
            handleBack(){
                this.$emit('onClose')
            },
            handleReset() {
                console.log(this.startTime)
                this.startTime2 = this.startTime;
                this.endTime2 = this.endTime;
                this.status = this.state;
            },
            handleSubmit(){
                console.log(this.startTime)
                const {status,startTime2,endTime2} = this;
                this.$emit('onConfirm',{state:status,startDay:startTime2,endDay:endTime2})
            }
        },
    }
</script>

<style lang="scss" scoped>
    #screen{
        height: inherit;
        height: 100%;
        /deep/ .yd-scrollview{
            background: #fff;
        }
    }
    .item{
        padding:.3rem .3rem 0;
        .label{
            color: var(--text-color-default);
            margin-bottom: .3rem;
        }
        .item-body{
            border-bottom: 1px solid var(--border-color-input);
            .yd-radio{
                width: 33.3%;
                padding-bottom: .3rem;
                /deep/ .yd-radio-text{
                    font-size: .32rem;
                    color:var(--text-color-primary);
                    display: inline-block;
                    width: calc(100% - 26px);
                    line-height: 15px;
                    font-weight: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        .time-box{
            display: flex;
            padding-bottom: .3rem;
            .middle{
                line-height:.64rem;
                margin:0 .3rem;
            }
            .yd-datetime-input{
                height: .64rem;
                line-height:.64rem;
                text-align: center;
                border-radius: .12rem;
                width: 2.2rem;
                font-size: .28rem;
                background: rgba(241, 246, 245, .5);
            }
            
        }
        
        
    }
    .yd-tabbar{
        border-color: var(--border-color);
        .box{
            display: flex;
            width: 100%;
            padding:.2rem .3rem;
            height: inherit;
            button{
                flex:1;
                height: .92rem;
                margin-left:.18rem;
                border-radius: .12rem;
                font-size: .32rem;
                font-weight: 500;
            }
            button:first-child{
                margin-left: 0;
            }
        }
    }
</style>