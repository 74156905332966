<template>
    <div class="PopularizeList">
        <div class="nav">
            <div class="title">
                <ul class="btn-group">
                    <li @click="handleTab(1)" :class="{active:options.type==1}" class="btn-item">商户</li>
                    <li @click="handleTab(2)" :class="{active:options.type==2}" class="btn-item">供应商</li>
                </ul>
                <span @click="handleScreen" class="right">
                    <i class="iconfont iconshaixuanscreen2"></i>筛选
                </span>
            </div>
            <div class="census wrap">
                <span class="time">
                    <span>{{options.startDay | filSearchTime}}</span> 
                    至
                    <span>{{options.endDay | filSearchTime}}</span>
                </span>
                <div class="right">
                    <div class="census-item">
                        <h5 class="count">{{expandCount}}</h5>
                        <span class="label">推广户数</span>
                    </div>
                    <div class="census-item">
                        <h5 class="count">{{successCount}}</h5>
                        <span class="label">通过户数</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <!-- <div class="list-i-box">
                <p class="time">2020-12-02</p>
                <div>
                    <div class="list-content">
                        <div class="list-i-title list-item">
                            <div class="left content-title">
                                天天火锅店1
                            </div>
                            <span class="list-i-right"> 审核中 </span>
                        </div>
                        <div class="list-i-body">
                            <div class="list-body-content">
                                <p class="body-content-box">
                                    <span class="label">店主姓名：</span>
                                    <span class="body-content-text">张三</span>
                                </p>
                                <p class="body-content-box">
                                    <span class="label">联系电话：</span>
                                    <span class="body-content-text">
                                        132 5000 0560 
                                        <img src="../../assets/icon_phone_i@2x.png" alt="" srcset="../../assets/icon_phone_i@2x.png 2x,../../assets/icon_phone_i@3x.png 3x">
                                    </span>
                                </p>
                                <p class="body-content-box">
                                    <span class="label">店铺地址：</span>
                                    <span class="body-content-text">广东省深圳市罗湖区深南东路5016号 京基100大厦57层</span>
                                </p>
                                <p class="body-content-box">
                                    <span class="label">注册时间：</span>
                                    <span class="body-content-text">2020-12-03  12:12:12</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- 滚动加载 -->
            <yd-pullrefresh :callback="refreshList" ref="pullrefreshDemo">
                <yd-infinitescroll :callback="loadList" ref="infinitescrollDemo">
                
                    <div class="list-i-box" slot="list">
                        <!-- <p class="time">{{it.registerTime | filTime}}</p> -->
                        <div>
                            <div class="list-content" v-for="it in dataList" :key="it.phone">
                                <div class="list-i-title list-item">
                                    <div class="left content-title">{{it.shopName}}</div>
                                    <span :class="{
                                        primary:it.stateResp==2,
                                        warn:it.stateResp ==3,
                                        disabled:it.stateResp ==5,
                                    }" class="list-i-right difeault"> 
                                        {{it.stateResp | filState}} 
                                    </span>
                                </div>
                                <div class="list-i-body">
                                    <div class="list-body-content">
                                        <p class="body-content-box">
                                            <span class="label">店主姓名：</span>
                                            <span class="body-content-text">{{it.realName}}</span>
                                        </p>
                                        <p class="body-content-box">
                                            <span class="label">联系电话：</span>
                                            <span class="body-content-text">
                                                {{it.phone | filPhone}}
                                                <img src="../../assets/icon_phone_i@2x.png" alt="" srcset="../../assets/icon_phone_i@2x.png 2x,../../assets/icon_phone_i@3x.png 3x">
                                            </span>
                                        </p>
                                        <p class="body-content-box">
                                            <span class="label">店铺地址：</span>
                                            <span class="body-content-text">{{it.address}}</span>
                                        </p>
                                        <p class="body-content-box">
                                            <span class="label">注册时间：</span>
                                            <span class="body-content-text">{{it.registerTime}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 数据全部加载完毕显示 -->
                    <div slot="doneTip">
                        <p v-show="!isNull" class="null">{{msg}}</p>
                        <div v-show="isNull" class="null-box wrap">
                            <img src="../../assets/banner_null@2x.png" srcset="../../assets/banner_null@2x.png 2x,../../assets/banner_null@3x.png 3x" alt="空">
                        </div>
                    </div>
                    <!-- 加载中提示，不指定，将显示默认加载中图标 -->
                    <p slot="loadingTip"  class="null">{{msg}}</p>
                    <!-- 数据全部加载完毕显示 -->
                    <!-- <span slot="doneTip">啦啦啦，啦啦啦，没有数据啦~~</span> -->

                    <!-- 加载中提示，不指定，将显示默认加载中图标 -->
                    <!-- <img slot="loadingTip" src="path/img/loading.svg"/> -->

                </yd-infinitescroll>
            </yd-pullrefresh>
        </div>
        <yd-popup v-model="isScreen" position="left" width="100%">
            <screen
                v-if="isScreen"
                :startTime="options.startDay"
                :endTime="options.endDay"
                :state="options.state"
                @onConfirm="handleConfirm"
                @onClose="handleScreen"
            />
        </yd-popup>
    </div>
</template>

<script>
    import {formatDate} from '@/utils/formatDate'
    import {getToken} from '@/utils/auth'
    import Screen from './Screen'

    export default {
        data() {
            return {
                options:{
                    currentPage :1, 
                    endDay :(new Date()).format('YYYY-MM-DD'), 
                    pageSize :6, 
                    startDay :'', 
                    state :1, //审核状态 1：全部（默认） 2：审核中 3：待激活 4：审核通过 5：审核不通过
                    type:1, //1：商户（默认） 2：供应商
                    userId:'', 
                },
                expandCount:0,
                successCount:0,
                dataList:[],
                total:0,
                msg:'',
                isNull:true,

                isScreen:false,
            }
        },
        components: {
            Screen,
        },
        props:{
            userId:{
                type:String,
                default:''
            },
            startTime:{
                type:String,
                default:''
            }
        },
        mounted () {
            this.options.startDay = this.formatDate('YYYY-MM-DD',new Date(this.startTime));
            this.refreshList()
        },
        filters: {
            filSearchTime: function(value) {
                return formatDate('MM.DD',new Date(value));
            },
            filTime: function(value) {
                return formatDate('YYYY-MM-DD',new Date(value));
            },
            filPhone: function(value) {
                // return value.replace(/(\d{3})(?=\d)/g, '$1 ');
                return value.replace(/(\d{3})(?=\d)(\d{4})(?=\d)/g, '$1 $2 ');
            },
            filState(val){
                return {
                    2:'审核中', 
                    3:'待激活', 
                    4:'审核通过', 
                    5:'审核不通过',
                }[val]
            },
        },
        methods: {
            formatDate,
            //刷新
            refreshList(){
                this.dataList = [];
                this.initList();
            },
            //下拉加载
            loadList(){
                this.param.currentPage += this.param.currentPage;
                this.initList()
            },
            //搜索确认
            handleConfirm(opts){
                this.isScreen = false;
                const {options} = this;
                this.options = {
                    ...options,
                    ...opts,
                    currentPage:1
                }
                this.dataList = [];
                this.initList()
            },
            //筛选
            handleScreen(){
                this.isScreen = !this.isScreen;
            },
            //商户、供应商
            handleTab(val) {
                this.dataList = [];
                this.options.type = val;
                this.handleResetOpt();
            },
            //重置参数
            handleResetOpt(){
                const {options} = this;
                this.options = {
                    ...options,
                    currentPage:1,
                    startDay:this.formatDate('YYYY-MM-DD',new Date(this.startTime)),
                    endDay : formatDate('YYYY-MM-DD'), 
                    state :1, 
                }
                this.isScreen = false;
                this.refreshList();
            },
            //重新登录
            handleReLogin(){
                let token = getToken();
                if(!token){
                    this.$router.push({path:'/login'});
                }
            },
            initList(){
                const {userId,options,$store,dataList} = this;
                let param = {
                    ...options,
                    userId,
                }
                this.msg = '数据加载中...';
                $store.dispatch('expandList',param)
                .then(({status,msg,data})=>{
                    this.$refs.pullrefreshDemo.$emit('ydui.pullrefresh.finishLoad');
                    /* 单次请求数据完毕 */
                    this.$refs.infinitescrollDemo.$emit('ydui.infinitescroll.finishLoad');
                    switch (status) {
                        case 200:
                            this.expandCount = data.expandCount || 0;
                            this.successCount = data.successCount || 0;
                            var total = data && data.expandList && data.expandList.total || 0;
                            var resList = data && data.expandList && data.expandList.list || [];
                            if(resList.length){
                                //订单去重
                                for(let i=0; i<resList.length;i++){
                                    for(let j=0; j<dataList.length; j++){
                                        if(resList[i].orderId == dataList[j].orderId){
                                            dataList.splice(j,1);
                                        }
                                    }
                                }
                            }
                            var arr = dataList.concat(resList || []);
                            this.dataList = arr;
                            this.total = total;
                            if(total){
                                this.isNull = false;
                                if(total<=arr.length){
                                    this.msg = '我也是有底线的~~';
                                    /* 所有数据加载完毕 */
                                    this.$refs.infinitescrollDemo.$emit('ydui.infinitescroll.loadedDone');
                                }else{
                                    this.msg = '下拉加载更多~~';
                                }
                                return;
                            }else {
                                this.isNull = true;
                            }
                            break;
                        default:
                            this.handleReLogin();
                            this.$dialog.toast({
                                mes: msg || '获取数据失败',
                                timeout: 1500,
                                icon: 'error',
                            });
                            break;
                    }
                })
            },
            
        },
    }
</script>

<style lang="scss" scoped>
    .PopularizeList{
        background: var(--border-color-input);
        height: inherit;
    }
    // 按钮组
    .btn-group{
        display: inline-block;
        border: 1px solid var(--color-primary);
        border-radius: .12rem;
        .btn-item{
            float: left;
            min-width: 1.96rem;
            padding: .14rem .16rem;
            font-size: .32rem;
            font-weight: normal;
        }
        .active{
            background: var(--color-primary);
            color: #fff;
        }
    }
    // 按钮组 end
    //固定导航
    .nav{
        position: fixed;
        top: .88rem;
        left: 0;
        right: 0;
        z-index: 99;
    }
    // 导航主体
    .title{
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        padding: .32rem;
        background: #ffff;
        text-align: center;
        .right{
            position: absolute;
            right: .32rem;
            margin: auto;
            vertical-align: middle;
            font-size: .32rem;
            font-weight: normal;
            height: .72rem;
            line-height: .72rem;
            .iconfont{
                vertical-align: middle;
            }
        }
    }
    //统计数据
    .census{
        padding: .28rem .32rem;
        background: #ffff;
        border-top: 1px solid var(--border-color-input);
        .time{
            font-size: .32rem;
            font-weight: normal;
            line-height: .8rem;
        }
        .right{
            float: right;
            .census-item{
                float: left;
                text-align: center;
                margin-left:.32rem;
                .count{
                    font-size: .32rem;
                    font-weight: 500;
                    margin-bottom: .1rem;
                }
                .label{
                    font-size: .24rem;
                    color: var(--text-color-label);
                }
            }
        }
    }
    .main{
        padding-top: 2.82rem;
        .null{
            color: var(--text-color-default);
            font-size: .24rem;
            text-align: center;
            padding:.32rem;
        }
        .null-box{
            text-align: center;
            margin:15vh auto;
        }
    }
    .difeault{
        color: var(--text-color-label);
    }
    .primary{
        color: var(--color-primary);
    }
    .warn{
        color: var(--text-color-warn);
    }
    .disabled{
        color: var(--text-color-default);
    }
    .list-i-box{
        .time{
            color: var(--text-color-default);
            font-size: .24rem;
            text-align: center;
            padding:.32rem;
        }
        .list-content:first-child{
            border-top:0;
        }
        .list-content{
            background: #fff;
            border-top: 1px solid var(--border-color-input);
            /**左右布局**/
            .list-item{
                display: flex;
                padding:.32rem;
            }
            .list-item:first-child {
                margin-top: 0;
            }
            .list-i-right{
                flex: 1;
                text-align: right;
                font-size: .28rem;
            }
            /**左右布局 end**/
            .list-i-title{
                .left{
                    font-size: .32rem;
                    font-weight: normal;
                }
            }
            .list-i-body {
                padding:0 .32rem .32rem;
                .list-body-content{
                    background: rgba(241, 246, 245, .5);
                    padding: .32rem;
                    border-radius: .12rem;
                    .body-content-box{
                        margin-top: .1rem;
                        font-size: .28rem;
                        display: flex;
                        .label{
                            color: var(--text-color-default);
                        }
                        .body-content-text{
                            flex: 1;
                            color: var(--text-color-label);
                            line-height: 20px;
                            position: relative;
                            img{
                                vertical-align: bottom;
                                margin-left:.1rem;
                            }
                        }
                    }
                    .body-content-box:first-child{
                        margin-top: 0;
                    }
                }
            }

        }
    }
    .content-title{
        position: relative;
    }
    .content-title::after{
        content: '';
        display: inline-block;
        position: absolute;
        bottom: .04rem;
        left: -.32rem;
        top:.08rem;
        margin: auto;
        width: .08rem;
        background: #FA8835;
    }
    .yd-popup-content{
        /deep/ &>div{
            height: 100%;
        }
    }
    
</style>